import { ReactElement } from 'react'
import { Stack } from 'syf-component-library/ui/spacing'
import { H2, H4 } from 'syf-component-library/ui/typography'
import { Description, TextWrapper } from './subcomponents'
import { ContentWrapper, ImageWrapper } from '../subcomponents'

interface AddAccountResponseProps {
  image: string
  title: string
  description: string
  isDesktop: boolean
}

const AddAccountResponseScreen = ({
  image,
  title,
  description,
  isDesktop
}: AddAccountResponseProps): ReactElement => {
  return (
    <ContentWrapper data-test={title}>
      <ImageWrapper>
        <img src={image} alt={`${title} Banner`} />
      </ImageWrapper>
      <Stack size="large" />
      <TextWrapper>
        {isDesktop ? (
          <H2 weight="bold">{title}</H2>
        ) : (
          <H4 weight="bold">{title}</H4>
        )}
      </TextWrapper>
      <Stack size="xsmall" />
      <Description>{description}</Description>
    </ContentWrapper>
  )
}

export default AddAccountResponseScreen
