import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import getMFEConfig from 'helpers/getMFEConfig'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import Promotions from 'promotions-mfe'

const PromotionsPage = () => {
  const promotionsMfeConfig = getMFEConfig('mma')

  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )
  useNavigateOnRedirect()
  return (
    <Promotions
      config={promotionsMfeConfig}
      accountId={selectedAccount}
      accountIdType="guid"
    />
  )
}
export default PromotionsPage
