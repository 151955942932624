import styled from 'styled-components'

import { Body } from 'syf-component-library/ui/typography'
import { Button } from 'syf-component-library/ui/atoms'
import query from 'syf-component-library/const/mediaQueries'

export const CreditViewErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 125px auto 60px;
  @media ${query.lessThanLarge} {
    padding: 32px 16px 16px;
    margin: 0;
  }
`

export const CreditViewErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 16px;
  @media ${query.lessThanMedium} {
    width: 100%;
    border-radius: 8px;
  }
`

export const CreditViewErrorImage = styled.img`
  height: 240px;
  transform: translateY(-21px);
  margin-bottom: -21px;
  width: 100%;
  @media ${query.lessThanMedium} {
    height: 120px;
    transform: translateY(-16px);
    margin-bottom: -16px;
  }
`

export const ContentWrapper = styled.div`
  max-width: 489px;
  text-align: center;
  padding: 40px;
  @media ${query.lessThanMedium} {
    padding: 24px;
  }
`

export const ErrorContent = styled(Body)`
  font-size: 14px;
  font-weight: 400;
`

export const BackButton = styled(Button)`
  padding: 12px 24px;
  margin: 0 auto;
  @media ${query.lessThanSmall} {
    width: 100%;
  }
`
