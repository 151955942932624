import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import AccountDetails from 'account-details-mfe'
import config from 'const/config'
import featureFlags from 'const/featureFlags'
import { accountDetailsModalContainerId } from 'const/modalContainerId'
import { getLanguageFromStorage } from 'helpers'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { MFEConfig } from 'syf-js-utilities/typings/mfeConfig'
import findAccount from 'helpers/findAccount'
import { useAccountSummary, useCreditAccounts } from 'const/syfApiQueries'
import getHelpNumber from 'helpers/getHelpNumbers'

const DetailsModal = (): ReactElement => {
  const dispatch = useDispatch()
  const { selectedAccount, modalId, modalTriggerId } = useSelector(
    (state: RootState) => ({
      selectedAccount: state.modals.selectedAccount,
      modalId: state.modals.modalId,
      modalTriggerId: state.modals.modalTriggerId
    })
  )

  const { data: { accounts = [] } = {} } = useCreditAccounts()
  const { data: accountSummary } = useAccountSummary(selectedAccount)

  const account = findAccount(accounts, selectedAccount)
  const isRegistered = account?.registration?.registered
  const clientId = accountSummary?.product?.clientId
  const { type: productType } = account?.product || {}
  const helpNumber = getHelpNumber(clientId, productType)

  const accountDetailsConfig: MFEConfig = {
    hosts: {
      apigee: new URL(config.API_HOST),
      assets: new URL(config.ASSETS_HOST)
    },
    apiErrorCallback: (err) => err,
    lang: getLanguageFromStorage()
  }

  const handleCloseModal = () => {
    if (modalTriggerId && document.getElementById(modalTriggerId)) {
      document.getElementById(modalTriggerId).focus()
    }
    dispatch(updateModalStatus('', '', true))
  }

  return (
    <AccountDetails
      containerId={accountDetailsModalContainerId(selectedAccount)}
      config={accountDetailsConfig}
      isModalOpen={modalId === 'Details'}
      handleClose={handleCloseModal}
      selectedAccountId={selectedAccount}
      triggerAnalyticsEvent={triggerAnalyticsEvent}
      featureFlags={{
        isNotificationsEnabled: featureFlags.isNotificationsEnabled,
        isIRLEnabled: featureFlags.isIRLEnabled
      }}
      isRegistered={isRegistered}
      helpNumber={helpNumber}
    />
  )
}

export default DetailsModal
