import { ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import config from 'const/config'
import routes from 'const/routes'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import i18n from 'strings/i18n'
import Card from 'syf-component-library/ui/atoms/Card/Card'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import { H2, H4, H5 } from 'syf-component-library/ui/typography'

import MarketPlace from 'ui/patterns/MarketPlace'
import featureFlags from 'const/featureFlags'
import { addOneTrustEvent } from 'helpers/initializeOneTrust'
import getDataSubjectParams from 'helpers/getDataSubjectParams'
import {
  Container,
  InnerContainer,
  LoginButton,
  LogoutHeader,
  LogoutTitle
} from './subcomponents'

const Logout = (): ReactElement => {
  const navigate = useNavigate()

  useEffect(() => {
    const dataSubjectParams = getDataSubjectParams()
    window.OneTrust = {
      dataSubjectParams
    }

    addOneTrustEvent()

    triggerAnalyticsEvent({
      PageFunction: 'logout',
      pageName: 'complete',
      PageKind: 'logout'
    })
  }, [])

  return (
    <Container>
      <LogoutTitle all="base">
        <H2 weight="light">{i18n({ logout: 'pageTitle' })}</H2>
      </LogoutTitle>
      <InnerContainer>
        <Stack size="medium" hideOnBreakpointUp="medium" />
        <Stack size="large" hideOnBreakpointDown="medium" />
        <Card>
          <LogoutHeader>
            <img
              src={`${config.ASSETS_HOST}/assets/vista/logout_image.svg`}
              alt={`${i18n({ logout: 'pageTitle' })}`}
              data-test="logout-image"
            />
          </LogoutHeader>
          <Inset horizontal="xxlarge" vertical="base">
            <H4 as="h3" weight="bold">
              {i18n({ logout: 'cardTitle' })}
            </H4>
            <Stack size="xsmall" />
            <H5 as="h4">{i18n({ logout: 'cardDescription' })}</H5>
            <Stack size="large" />
            <LoginButton
              buttonType="primary"
              onClick={() => navigate(routes.HOME)}
              data-test="login-button"
              data-type="Session logout"
              data-reason="Log back in"
              data-analytics="clicktrackingevent"
            >
              {i18n({ logout: 'button' })}
            </LoginButton>
          </Inset>
        </Card>
      </InnerContainer>
      {featureFlags.isMarketPlaceEnabled && <MarketPlace />}
    </Container>
  )
}

export default Logout
