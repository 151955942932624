import { ReactElement, useState } from 'react'
import {
  FAS_CHEVRON_DOWN,
  FAS_CHEVRON_RIGHT,
  FAS_CHEVRON_UP
} from 'const/icons'
import Collapse from 'syf-component-library/ui/atoms/Collapse/Collapse'
import i18n from 'strings/i18n'
import useProfileNavigate from 'hooks/useProfileNavigate'
import { NotificationList } from 'hooks/useNotificationCenter'
import {
  AccountList,
  AccountName,
  AttentionTitle,
  CollapseButton,
  CollapseIcon,
  IssueTitle,
  NotificationCard,
  NotificationCardBody,
  NotificationCardHeader,
  NotificationCount,
  NotificationHeader,
  NotificationWrapper,
  ReviewProfileLink,
  ReviewProfileText,
  RightAngleIcon,
  RoundCircle
} from './subcomponents'

type NotificationCenterProps = {
  accounts: NotificationList[]
}

const NotificationCenter = ({
  accounts
}: NotificationCenterProps): ReactElement => {
  const { handleProfileClick } = useProfileNavigate()
  const [open, setOpen] = useState(false)
  const collapseIcon = open ? FAS_CHEVRON_UP : FAS_CHEVRON_DOWN
  const handleClick = () => setOpen(!open)

  if (accounts?.length <= 0) {
    return null
  }

  return (
    <NotificationWrapper>
      <NotificationHeader
        as="h2"
        data-test="notification-center-header"
        weight="bold"
      >
        {i18n({ notificationCenter: 'headerTitle' })}
      </NotificationHeader>
      <NotificationCard>
        <NotificationCardHeader>
          <RoundCircle>
            <NotificationCount
              data-test="notification-center-count"
              weight="bold"
            >
              {accounts?.length}
            </NotificationCount>
          </RoundCircle>
          <AttentionTitle
            data-test="notification-center-attention-title"
            weight="bold"
          >
            {accounts.length > 1
              ? i18n({ notificationCenter: 'someAccAttentionTitle' })
              : i18n({ notificationCenter: 'oneAccAttentionTitle' })}
          </AttentionTitle>
          <CollapseButton onClick={handleClick}>
            <CollapseIcon icon={collapseIcon} />
          </CollapseButton>
        </NotificationCardHeader>
        <Collapse expand={open} id="collapse-id">
          <NotificationCardBody>
            <IssueTitle data-test="notification-center-issue-description">
              {i18n({
                notificationCenter:
                  accounts?.length > 1
                    ? 'potentialIssuesTitle'
                    : 'potentialIssueTitle'
              })}
            </IssueTitle>
            <AccountList data-test="notification-center-accounts">
              {accounts?.map(({ accountId, accountName }) => (
                <AccountName
                  data-test="notification-center-account-name"
                  key={accountId}
                >
                  {accountName}
                </AccountName>
              ))}
            </AccountList>
            <ReviewProfileLink
              data-object="button"
              data-type="dashboard"
              data-reason="Review profile now"
              data-analytics="clicktrackingevent"
              data-test="notification-center-review-btn"
              onClick={() => handleProfileClick('')} // The empty string parameter can be removed when we do cleanup of useProfileNavigate hook
            >
              <ReviewProfileText>
                {i18n({ notificationCenter: 'reviewButtonTitle' })}
              </ReviewProfileText>
              <RightAngleIcon icon={FAS_CHEVRON_RIGHT} />
            </ReviewProfileLink>
          </NotificationCardBody>
        </Collapse>
      </NotificationCard>
    </NotificationWrapper>
  )
}

export default NotificationCenter
