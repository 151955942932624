import revokeAccessToken from 'authorization-module/helpers/revokeAccessToken'

import getApigeeClientId from './getApigeeClientId'
import config from '../const/config'

const handleRevokeAccessToken = async (): Promise<void> => {
  const accessToken = sessionStorage.getItem('accessToken')
  if (!accessToken) return
  sessionStorage.removeItem('accessToken')
  const client = localStorage.getItem('client')
  const clientId = getApigeeClientId(client)
  await revokeAccessToken(config.API_HOST, clientId, accessToken)
}

export default handleRevokeAccessToken
