import apiEndpoints from 'const/apiEndpoints'
import config from 'const/config'
import routes from 'const/routes'
import clearSessionStorage from 'helpers/clearSessionStorage'

import { defaultDataSubjectParams } from './getDataSubjectParams'
import handleRevokeAccessToken from './handleRevokeAccessToken'
import redirectToUrl from './redirectToUrl'

const handleLogout = async (): Promise<void> => {
  const correlationId = sessionStorage.getItem('correlationId')
  const dataSubjectParams =
    window.OneTrust?.dataSubjectParams ?? defaultDataSubjectParams

  await handleRevokeAccessToken()
  clearSessionStorage()

  sessionStorage.setItem(correlationId, JSON.stringify(dataSubjectParams))

  redirectToUrl(
    `${config.AUTH_DOMAIN}${apiEndpoints.LOGOUT}?post_logout_redirect_uri=${window.location.origin}${config.BASENAME}${routes.LOGOUT}`
  )
}

export default handleLogout
