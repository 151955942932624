const routes = {
  ACCOUNT_SERVICES: '/account-services',
  ACTIVITY: '/activity',
  ALERTS: '/alerts',
  CALLBACK: '/callback',
  CREDIT_BALANCE_REFUND: '/refund',
  CREDIT_SCORE: '/credit-score',
  CREDIT_VIEW: '/credit-view',
  CREDIT_VIEW_ERROR: '/credit-view-error',
  DISPUTES: '/activity/disputes',
  ERROR: '/error',
  HOME: '/',
  LOGOUT: '/logout',
  MARKETING_PRIVACY: '/marketing-privacy',
  PROFILE: '/profile',
  PROMOTIONS: '/promotions',
  REWARDS_ERROR: '/rewards-error',
  REWARDS: '/rewards'
} as const

type Valueof<T> = T[keyof T]

export type Routes = Valueof<typeof routes>

export type RouteKeys = keyof typeof routes

export default routes
