import { ReactElement } from 'react'

import getMFEConfig from 'helpers/getMFEConfig'
import { handleMFEAuthenticationRedirect } from 'helpers/authenticationRedirect'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import ProfileMFE from 'profile-mfe'

const Profile = (): ReactElement => {
  const profileConfig = getMFEConfig('mfe')

  useNavigateOnRedirect()

  return (
    <ProfileMFE
      config={profileConfig}
      authenticationRedirect={handleMFEAuthenticationRedirect}
    />
  )
}

export default Profile
