import config from 'const/config'
import i18n from 'strings/i18n'
import { ModalFooter } from 'syf-component-library/ui/patterns/SlideInModal'
import { FooterContainer } from 'syf-component-library/ui/patterns/SlideInModal/subcomponents'
import AddAccountScreen from '../AddAccountScreen'
import { ManageFormHelpers, ScreenType } from '../typings'
import AddAccountResponseScreen from '../AddAccountResponseScreen'
import { getButtonDetails } from './getButtonDetails'
import { getAnalyticsData } from './getAnalyticsData'

const getErrorScreen = (screenType: ScreenType, isDesktop: boolean) => {
  let image = ''
  let title = ''
  let description = ''

  const assetUrlBase = `${config.ASSETS_HOST}/assets/vista`

  switch (screenType) {
    case 'Success':
      image = `${assetUrlBase}/add_account_success.png`
      title = `${i18n({ addAccountModal: 'successTitle' })}`
      description = `${i18n({ addAccountModal: 'successDescription' })}`
      break

    case 'MaxAttempt':
      image = `${assetUrlBase}/max_attempts.svg`
      title = `${i18n({ addAccountModal: 'maxAttemptsTitle' })}`
      description = `${i18n({ addAccountModal: 'maxAttemptsDescription' })}`
      break

    case 'AlreadyAdded':
      image = `${assetUrlBase}/add_account_success.png`
      title = `${i18n({ addAccountModal: 'alreadyAddedTitle' })}`
      description = `${i18n({ addAccountModal: 'alreadyAddedDescription' })}`
      break

    case 'UnableToAdd':
      image = `${assetUrlBase}/technical_error_cat.svg`
      title = `${i18n({ addAccountModal: 'unableToAddTitle' })}`
      description = `${i18n({ addAccountModal: 'callCustomerService' })}`
      break

    case 'SomethingWrongForThisAccount':
      image = `${assetUrlBase}/technical_error_cat.svg`
      title = `${i18n({ addAccountModal: 'syfIDErrorTitle' })}`
      description = `${i18n({ addAccountModal: 'callCustomerService' })}`
      break

    case 'NotSupported':
      image = `${assetUrlBase}/max_attempts.svg`
      title = `${i18n({ addAccountModal: 'notSupportedTitle' })}`
      description = `${i18n({ addAccountModal: 'notSupportedDescription' })}`
      break

    case 'SomethingWrong':
      image = `${assetUrlBase}/technical_error_cat.svg`
      title = `${i18n({ addAccountModal: 'generalErrorTitle' })}`
      description = `${i18n({ errors: 'pleaseTryAgain' })}`
      break

    default:
      break
  }

  return (
    <AddAccountResponseScreen
      image={image}
      title={title}
      description={description}
      isDesktop={isDesktop}
    />
  )
}

export const renderScreen = (
  screenType: ScreenType,
  manageFormState: ManageFormHelpers,
  isDesktop: boolean,
  handleAddCard: () => Promise<void>,
  handleClose: () => void
) => {
  const {
    formData,
    formError,
    setFormError,
    handleInputChange,
    validateInput
  } = manageFormState

  const {
    primaryButtonText,
    secondaryButtonText,
    handlePrimarybuttonClick,
    handleSecondarybuttonClick
  } = getButtonDetails(screenType, handleAddCard, handleClose)

  const {
    buttonDetails: {
      dataReasonPrimary,
      dataReasonSecondary,
      dataTypePrimary,
      dataTypeSecondary
    }
  } = getAnalyticsData(screenType)

  return (
    <>
      {(['Default', 'IncorrectInfo'] as ScreenType[]).includes(screenType) ? (
        <AddAccountScreen
          formData={formData}
          formError={formError}
          handleInputChange={handleInputChange}
          validateInput={validateInput}
          setFormError={screenType === 'IncorrectInfo' && setFormError}
          shouldShowIncorrectInfoError={screenType === 'IncorrectInfo'}
        />
      ) : (
        getErrorScreen(screenType, isDesktop)
      )}
      <FooterContainer>
        <ModalFooter
          primaryText={primaryButtonText}
          secondaryText={secondaryButtonText}
          handlePrimaryButton={handlePrimarybuttonClick}
          handleSecondaryButton={handleSecondarybuttonClick}
          dataPrimaryReason={dataReasonPrimary}
          dataSecondaryReason={dataReasonSecondary}
          dataPrimaryType={dataTypePrimary}
          dataSecondaryType={dataTypeSecondary}
          dataPrimaryObject="button"
          dataSecondaryObject="button"
          secondaryButtonType="ghost"
        />
      </FooterContainer>
    </>
  )
}
