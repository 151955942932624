import styled from 'styled-components'
import mediaQueries from 'syf-component-library/const/mediaQueries'
import { fontSizes, spacing } from 'syf-component-library/defaultTheme'
import { Button } from 'syf-component-library/ui/atoms'
import { H4 } from 'syf-component-library/ui/typography'

export const ContentWrapper = styled.div`
  padding: 12px 16px;

  @media ${mediaQueries.lessThanMedium} {
    text-align: center;
  }
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: ${spacing.large}px;
  padding-inline: 16px;

  @media ${mediaQueries.lessThanLarge} {
    grid-template-rows: 4fr;
    grid-template-columns: none;
    gap: 10px;
    padding: 0;
  }
`

export const GridItem = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 25px;
    width: 25px;
  }

  @media ${mediaQueries.lessThanLarge} {
    justify-content: center;

    svg {
      height: ${spacing.base}px;
      width: ${spacing.base}px;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const FullWidthButton = styled(Button)`
  @media ${mediaQueries.lessThanSmall} {
    width: 100%;
    max-width: 340px;
  }
`

export const CreditViewSubtitle = styled(H4)`
  @media ${mediaQueries.lessThanMedium} {
    font-size: ${fontSizes.root}px;
  }
`

export const CreditScoreBenefit = styled(H4)`
  @media ${mediaQueries.lessThanMedium} {
    font-size: ${fontSizes.root}px;
  }
`
