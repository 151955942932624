import Loader from 'authorization-module/ui/atoms/Loader'
import { ReactElement } from 'react'

interface FullPageRouteProps {
  featureFlag: boolean
  children: ReactElement
}

const FullPageRoute = ({
  featureFlag,
  children
}: FullPageRouteProps): ReactElement => {
  return featureFlag ? children : <Loader />
}

export default FullPageRoute
