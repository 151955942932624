import formPost from 'helpers/formPost'

const handleCreditViewPlatform = (
  samlToken: string,
  creditViewDestination: string
) => {
  if (!samlToken) return
  const formData = {
    SAMLResponse: samlToken
  }
  formPost(creditViewDestination, formData, '_blank')
}

export default handleCreditViewPlatform
