import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faExternalLink,
  faChartLine as fasChartLine,
  faCheck as fasCheck,
  faChevronDown as fasChevronDown,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faEllipsisH as fasEllipsisH,
  faExclamationCircle as fasExclamationCircle,
  faGift as fasGift,
  faTimes as fasTimes,
  faUserCog as fasUserCog
} from '@fortawesome/free-solid-svg-icons'
import {
  faAngleUp,
  faBell,
  faCalculatorAlt,
  faCalendarAlt,
  faCalendarExclamation,
  faChartPieAlt,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faClock,
  faCreditCard,
  faCreditCardBlank,
  faDonate,
  faEllipsisH,
  faFileInvoice,
  faFileInvoiceDollar,
  faGlobe,
  faHandHoldingUsd,
  faHandsUsd,
  faListAlt,
  faMedal,
  faMoneyCheckAlt,
  faPercentage,
  faPhone,
  faPhoneLaptop,
  faReceipt,
  faStarChristmas,
  faTrashAlt,
  faUserCog,
  faWallet,
  faAngleDown as falAngleDown,
  faAngleLeft as falAngleLeft,
  faAngleRight as falAngleRight,
  faCloudRain as falCloudRain,
  faExclamationTriangle as falExclamationTriangle,
  faGift as falGift
} from '@fortawesome/pro-light-svg-icons'
import {
  faAngleDown,
  faAngleLeft,
  faBars,
  faCalendarDay,
  faCalendarDays,
  faChartLine,
  faCheck,
  faCirclePlus,
  faDollarSign,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faGear,
  faInfoCircle,
  faLink,
  faMagnifyingGlass,
  faMessagePen,
  faPen,
  faSignOutAlt,
  faSpinner,
  faSquareSlidersVertical,
  faStoreAltSlash,
  faSync,
  faSyncAlt,
  faTimes,
  faTriangleExclamation,
  faUndoAlt,
  faUniversity,
  faUsdCircle,
  faUser,
  faXmark,
  faAngleUp as farAngleUp,
  faGlobe as farGlobe,
  faMoneyCheckAlt as farMoneyCheckAlt
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBadgePercent,
  faUserCircle,
  faCalendarAlt as fasCalendarAlt,
  faChartPieAlt as fasChartPieAlt,
  faCreditCard as fasCreditCard,
  faFileInvoice as fasFileInvoice,
  faListAlt as fasListAlt
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faAngleUp,
  faBadgePercent,
  faBars,
  faBell,
  faCalculatorAlt,
  faCalendarAlt,
  faCalendarDay,
  faCalendarExclamation,
  fasCalendarAlt,
  faCalendarDays,
  faChartPieAlt,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faCirclePlus,
  faClock,
  faCreditCard,
  faCreditCardBlank,
  faDollarSign,
  faDonate,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faGear,
  faGlobe,
  faHandHoldingUsd,
  faHandsUsd,
  faInfoCircle,
  falAngleDown,
  falAngleLeft,
  falAngleRight,
  falCloudRain,
  falExclamationTriangle,
  faExternalLink,
  falGift,
  faLink,
  faListAlt,
  faMedal,
  faMessagePen,
  faMoneyCheckAlt,
  faPen,
  faPercentage,
  faPhone,
  faPhoneLaptop,
  farAngleUp,
  faReceipt,
  farMoneyCheckAlt,
  fasChartLine,
  fasChartPieAlt,
  fasCheck,
  fasChevronDown,
  fasChevronRight,
  fasChevronUp,
  fasCreditCard,
  fasEllipsisH,
  fasExclamationCircle,
  fasFileInvoice,
  fasGift,
  faSignOutAlt,
  fasListAlt,
  faSpinner,
  faSquareSlidersVertical,
  faStarChristmas,
  fasTimes,
  faStoreAltSlash,
  fasUserCog,
  faSync,
  faSyncAlt,
  faTimes,
  faTrashAlt,
  faTriangleExclamation,
  faUndoAlt,
  faUniversity,
  faUsdCircle,
  faUser,
  faUserCircle,
  faUserCog,
  faWallet,
  faXmark,
  faChartLine,
  farGlobe,
  faMagnifyingGlass
)
