import { AriaRole, ReactElement, RefObject, forwardRef, useState } from 'react'

import { ACCOUNT_UPGRADING } from 'const/account/conditions'
import { PSCC, SETPAY } from 'const/account/products'
import { defaultCardArt } from 'const/assets'
import featureFlags from 'const/featureFlags'
import { FAR_BAR, FAR_XMARK } from 'const/icons'
import getSetpayLoanProcessingStatus from 'helpers/getSetpayLoanProcessingStatus'
import handleAccountLink from 'helpers/handleAccountLink'
import isLoanProductCard from 'helpers/isLoanProductCard'
import PaymentDetailHeader from 'pages/PageLayout/PaymentSectionHeader/PaymentDetailHeader'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { Inline, Stack } from 'syf-component-library/ui/spacing'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'
import CardBankDetails from 'ui/atoms/CardBankDetails'
import CardLogo from 'ui/atoms/CardLogo'

import isAccountLinkDisabledForAClient from 'helpers/isAccountLinkDisabledForAClient'
import {
  AccountHeaderGrid,
  AccountHeaderSeparator,
  AccountImageGrid,
  BarIconContainer,
  CardHeaderRow,
  CloseButton,
  HeaderWrapper,
  VerticalIconWrapper
} from './subcomponents'

interface AccountHeaderProps {
  accountSummary: AccountSummaryGetResponse
  account: Account
  handleIconClick?: () => void
  isDesktop?: boolean
  isExpanded?: boolean
  role?: AriaRole
  isSidebarNavPage?: boolean
  showBars?: boolean
}

const PageHeader = (
  {
    accountSummary,
    account,
    handleIconClick,
    isExpanded,
    isSidebarNavPage,
    isDesktop,
    showBars,
    ...props
  }: AccountHeaderProps,
  logoFocusRef: RefObject<HTMLButtonElement>
): ReactElement => {
  const windowWidth = useWindowSize()
  const { isLessThanMobile } = windowWidth

  const [isViewAccountDisabled, setIsViewAccountDisabled] = useState(false)

  const { registration, product: accountProduct, info } = account || {}
  const {
    card,
    conditions,
    installment,
    product: { clientId, displayName }
  } = accountSummary || {}
  const isRegistered = registration?.registered
  const productType = accountProduct?.type
  const isSetpayAccount = productType === SETPAY
  const isPN4orMonthlySetpayAccount = isLoanProductCard(productType)
  const cardArtLogo = card?.cardArtURL || defaultCardArt
  const isPSCCRevolvingAccount = accountProduct?.lineOfBusiness === PSCC
  const isAccountLinkDisabledForAClientId =
    isAccountLinkDisabledForAClient(clientId)

  const isAccountUpgrading =
    conditions?.includes(ACCOUNT_UPGRADING) &&
    featureFlags.isAccountUpgradingMessageEnabled

  const isSetpayLoanProcessing = getSetpayLoanProcessingStatus(
    installment,
    productType,
    conditions
  )

  const isLinkEnabled =
    isRegistered &&
    !isViewAccountDisabled &&
    !isSetpayLoanProcessing &&
    !isAccountUpgrading &&
    !isPN4orMonthlySetpayAccount &&
    clientId &&
    !isSetpayAccount &&
    !isPSCCRevolvingAccount &&
    !isAccountLinkDisabledForAClientId

  const isLinkEnabledOnMobile =
    (isExpanded || !isLessThanMobile) && isLinkEnabled

  const handleAccountClick = () => {
    setIsViewAccountDisabled(true)
    handleAccountLink(account, clientId, windowWidth)
  }

  return (
    <CardHeaderRow data-test="account-header" {...props}>
      <Stack />
      <HeaderWrapper data-test="header-wrapper">
        {!isDesktop && <Inline size="large" respondToBreakpoints={['small']} />}

        <AccountHeaderGrid>
          <AccountImageGrid item xs="auto" alignItems="center">
            <CardLogo
              handleOnClick={handleAccountClick}
              isLinkDisabled={!isLinkEnabledOnMobile}
              logo={cardArtLogo}
              ref={logoFocusRef}
            />
          </AccountImageGrid>
          <AccountHeaderSeparator>
            <Inline size="medium" />
          </AccountHeaderSeparator>
          <div>
            <CardBankDetails
              cardName={displayName || 'Your Synchrony Account'}
              isFullPage={isDesktop}
              accountLast4={info?.accountLast4}
              isSetpayAccount={isSetpayAccount}
              handleOnClick={handleAccountClick}
              isLinkDisabled={!isLinkEnabledOnMobile}
            />
          </div>
          {showBars && (
            <VerticalIconWrapper item xs="auto">
              <CloseButton buttonType="text" onClick={handleIconClick}>
                <BarIconContainer
                  data-test="header-chevron"
                  icon={isExpanded ? FAR_XMARK : FAR_BAR}
                />
              </CloseButton>
              <Inline size="medium" />
            </VerticalIconWrapper>
          )}
        </AccountHeaderGrid>
        {isDesktop && (
          <PaymentDetailHeader
            account={account}
            accountSummary={accountSummary}
          />
        )}
      </HeaderWrapper>

      <Stack />
    </CardHeaderRow>
  )
}

export default forwardRef(PageHeader)
