import config from 'const/config'
import { createGlobalStyle } from 'styled-components'
import { lineHeights } from 'syf-component-library/defaultTheme'

const globalFont = `

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url("${config.ASSETS_HOST}/assets/fonts/Bold/OpenSans-Bold.woff2") format('woff2');
  }  


  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
     font-display: fallback;
    src: url("${config.ASSETS_HOST}/assets/fonts/Semibold/OpenSans-Semibold.woff2")
      format('woff2');
  }


  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    url("${config.ASSETS_HOST}/assets/fonts/Regular/OpenSans-Regular.woff2") format('woff2');
  }


  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: url("${config.ASSETS_HOST}/assets/fonts/Light/OpenSans-Light.woff2") format('woff2');
  }
`
// modeled after https://www.joshwcomeau.com/css/custom-css-reset/
const resetStyles = `
  /*
    1. Use a more-intuitive box-sizing model.
  */
  *, *::before, *::after {
    box-sizing: border-box;
  }
  /*
    2. Remove default margin
  */
  * {
    margin: 0;
  }
  /*
    3. Allow percentage-based heights in the application
  */
  html, body {
    height: 100%;
  }
  /*
    Typographic tweaks!
    4. Add accessible line-height https://www.w3.org/WAI/WCAG21/Understanding/text-spacing.html
    5. Improve text rendering
  */
  body {
    line-height: ${lineHeights.root};
  }
  /*
    6. Improve media defaults
  */
  img, picture, video, canvas {
    display: block;
    max-width: 100%;
  }
  /*
    7. Remove built-in form typography styles
  */
  input, button, textarea, select {
    font: inherit;
  }
  /*
    8. Avoid text overflows
  */
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  /*
    9. Create a root stacking context
  */
  #root {
    isolation: isolate;
  }
`

/**
 * Barebone styling that only
 * sets font and basic styles.
 * @use in Error pages such as DisabledCookies
 */
export const GlobalBaseStyles = createGlobalStyle`
  ${globalFont}

  body {
    background-color: ${({ theme }) => theme.body.background};
    box-sizing: border-box;
    color: ${({ theme }) => theme.body.text};
    font-family: 'Open Sans';
    margin: 0;
  }
`

/**
 * App styling that sets
 * font, basic styles and background image.
 */
export const GlobalAppStyles = createGlobalStyle`
  ${globalFont}

  body {
    background: ${({ theme }) => theme.body.background};
    background-color: ${({ theme }) => theme.body.backgroundColor};
    background-position: fixed;
    background-size: cover;
    box-sizing: border-box;
    color: ${({ theme }) => theme.body.text};
    font-family: 'Open Sans';
    margin: 0;
  }

  ${resetStyles}
`
