import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateSelectedAccount } from 'redux/modals/actions'

import routes from 'const/routes'
import { useAccountSummary } from 'const/syfApiQueries'
import i18n from 'strings/i18n'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import { CreditBalanceRefundButtonStyle } from './subcomponents'

interface CreditBalanceRefundButtonProps {
  account: Account
  dataType?: string
}

const CreditBalanceRefundButton = ({
  account,
  dataType
}: CreditBalanceRefundButtonProps): ReactElement => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const accountId = account?.info['cipher.accountId']
  const { data: { product } = {} } = useAccountSummary(accountId)

  const handleOnClick = () => {
    analyticsConfig.defaults.Product = product?.type
    analyticsConfig.defaults.ClientName = product?.clientId
    dispatch(updateSelectedAccount(accountId))
    navigate(routes.CREDIT_BALANCE_REFUND)
  }

  return (
    <CreditBalanceRefundButtonStyle
      buttonType="secondary"
      onClick={handleOnClick}
      data-type={dataType || 'manage credit balance'}
      data-reason="manage credit balance"
      data-object="button"
      data-analytics="clicktrackingevent"
      data-test="manage-credit-balance-button"
    >
      {i18n({ button: 'creditBalanceRefund' })}
    </CreditBalanceRefundButtonStyle>
  )
}

export default CreditBalanceRefundButton
