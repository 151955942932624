import { ReactElement } from 'react'

import { PreferenceSettings } from 'alerts-mfe'
import getMFEConfig from 'helpers/getMFEConfig'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import useProfileNavigate from 'hooks/useProfileNavigate'
import { PlatformType } from 'alerts-mfe/lib/const/alertsConfig'

const MarketingAndPrivacy = (): ReactElement => {
  const { handleProfileClick } = useProfileNavigate()

  useNavigateOnRedirect()

  const mfeConfig = getMFEConfig('mfe')

  return (
    <PreferenceSettings
      config={mfeConfig}
      handleProfileClick={handleProfileClick}
      platform={PlatformType.DIGITAL_SERVICING}
    />
  )
}

export default MarketingAndPrivacy
