import { ReactElement } from 'react'

import { FAL_CLOUD_RAIN } from 'const/icons'
import i18n from 'strings/i18n'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import H2 from 'syf-component-library/ui/typography/H2'

import {
  DisabledCookiesError,
  ErrorCard,
  ErrorWrapper,
  FullPageError,
  NoAccountError,
  RainCloudsIcon,
  TechnicalDifficultiesError,
  TextWrapper
} from './subcomponents'
import { ErrorTypes, TechnicalErrorProp } from './typings'

const TechnicalError = ({ errorType }: TechnicalErrorProp): ReactElement => {
  const errors: Record<ErrorTypes, ReactElement> = {
    disabledCookies: <DisabledCookiesError />,
    technicalError: <TechnicalDifficultiesError />,
    fullPageError: <FullPageError />,
    noAccountsFound: <NoAccountError />
  }

  return (
    <ErrorWrapper bottom="xlarge">
      <ErrorCard data-test="technical-error">
        <TextWrapper>
          <Inset top="base" hideOnBreakpointDown="large" />
          <Stack size="base" />
          <RainCloudsIcon icon={FAL_CLOUD_RAIN} size="4x" />
          <Inset all="large">
            <H2 weight="light" data-test="error-page-header">
              {errorType === 'disabledCookies'
                ? 'Oops, we hit a snag…'
                : i18n({ errors: 'weApologize' })}
            </H2>
          </Inset>
          <Stack size="small" hideOnBreakpointDown="large" />
          {errors[errorType]}
        </TextWrapper>
      </ErrorCard>
    </ErrorWrapper>
  )
}

export default TechnicalError
