import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import { FC, ReactElement } from 'react'
import VantageMFE from 'vantage-mfe'
import getMFEConfig from 'helpers/getMFEConfig'
import { useNavigate } from 'react-router-dom'
import routes from 'const/routes'

const VantageScore: FC = (): ReactElement => {
  const navigate = useNavigate()

  useNavigateOnRedirect()

  const navigateToDashboard = () => navigate(routes.HOME)

  const mfeConfig = getMFEConfig('mfe')

  return (
    <VantageMFE config={mfeConfig} navigateToDashboard={navigateToDashboard} />
  )
}

export default VantageScore
