import { ReactElement, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import { CreditLimitIncrease } from 'account-services-mfe'
import getMFEConfig from 'helpers/getMFEConfig'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import {
  ModalHeader,
  SlideInModalPortal
} from 'syf-component-library/ui/patterns/SlideInModalPortal'
import { creditLimitIncreaseContainerId } from 'const/modalContainerId'
import i18n from 'strings/i18n'

const CreditLimitIncreaseModal = (): ReactElement => {
  const dispatch = useDispatch()
  const headerRef = useRef(null)

  const { selectedAccount, modalId, modalTriggerId } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId'),
      modalId: state.modals.modalId,
      modalTriggerId: state.modals.modalTriggerId
    }),
    shallowEqual
  )

  const mfeConfig = getMFEConfig('mfe')

  const handleCloseModal = () => {
    dispatch(updateModalStatus(''))
    triggerAnalyticsEvent({
      pageFunction: 'account services',
      pageKind: 'account services'
    })
  }

  return (
    <SlideInModalPortal
      id={`cli-slide-in-modal${selectedAccount}`}
      containerId={creditLimitIncreaseContainerId(selectedAccount)}
      escapeModalHandler={handleCloseModal}
      isOpen={modalId === 'CreditLimitIncrease'}
      modalTriggerId={modalTriggerId}
      header={
        <ModalHeader
          closeModal={handleCloseModal}
          headerRef={headerRef}
          content={i18n({ button: 'creditLimitIncrease' }) as string}
          data-test="credit-limit-increase-modal-header"
          dataType="x button"
        />
      }
    >
      <CreditLimitIncrease
        config={mfeConfig}
        selectedAccountId={selectedAccount}
        handleClose={handleCloseModal}
        triggerAnalyticsEvent={triggerAnalyticsEvent}
      />
    </SlideInModalPortal>
  )
}

export default CreditLimitIncreaseModal
