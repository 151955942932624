const endpoints = {
  ACCOUNT_FEATURES: '/v1/utility/account-management/product/features',
  ACCOUNT_REWARDS: '/v1/utility/saml-token/rewards',
  ACCOUNTS_ACTIVITY: '/v1/credit/accounts/activity',
  ACCOUNTS_SUMMARY: '/v2/credit/accounts/summary',
  ACCOUNTS: '/v2/credit/accounts',
  ADD_MISSING_ACCOUNT: 'v1/credit/accounts/mismatch/addMissingAccount',
  AUTO_REGISTER: '/v1/credit/profiles/account-associations/sync',
  AUTOPAY: '/v1/credit/billing/payments/autopay',
  CREDIT_VIEW_DASHBOARD: '/v1/utility/saml-token/credit-view-dashboard',
  ELECTRONIC_CONSENT: '/v1/credit/accounts/electronic-preferences/acknowledge',
  JWT_TOKEN: '/v1/utility/jwt/generate',
  LOGOUT: '/v3/oauth2/logout',
  PROFILE: '/v1/credit/accounts/profile'
}

export default endpoints
