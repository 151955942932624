import { useQuery } from '@tanstack/react-query'
import {
  useAccountFeatures,
  useAccountSummary,
  useCreditAccounts
} from 'const/syfApiQueries'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import config from 'const/config'
import { updateModalStatus, updateSelectedAccount } from 'redux/modals/actions'
import featureFlags from 'const/featureFlags'
import handleAccountLink from 'helpers/handleAccountLink'
import routes from 'const/routes'
import findAccount from 'helpers/findAccount'
import { debounce } from 'syf-js-utilities'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import handleRewardsPlatform from 'helpers/handleRewardsPlatform'
import queryClient from 'const/queryClient'
import clearSessionStorage from 'helpers/clearSessionStorage'
import handleRevokeAccessToken from 'helpers/handleRevokeAccessToken'
import redirectToUrl from 'helpers/redirectToUrl'
import handoverToPayAsGuest from 'helpers/handoverToPayAsGuest'
import handoverToRegisterAccount from 'helpers/handoverToRegisterAccount'
import getRewardsResponse from 'helpers/getRewardsResponse'

interface RewardsResponse {
  samlToken: string
}

const useGetCTAHandlers = (
  accountId: string,
  setIsCrossBorderVisible?: (flag: boolean) => void
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const windowWidth = useWindowSize()

  const { data: { accounts = [] } = {} } = useCreditAccounts()
  const { data: accountSummary } = useAccountSummary(accountId)
  const { data: accountFeatures } = useAccountFeatures(accountId)
  const account = findAccount(accounts, accountId)

  const { product } = accountSummary || {}
  const { type: productType, clientId = '', lineOfBusiness } = product || {}

  const { ssoEnabled, rewardsPlatformDestination, accessToRewardsMfe } =
    accountFeatures?.rewardDetails || {}

  const statementsButtonId = `statements-${accountId}`
  const rewardsButtonId = `rewards-${accountId}`
  const activityButtonId = `activity-${accountId}`
  const detailsButtonId = `details-${accountId}`
  const paymentButtonId = `map-${accountId}`

  const samlTokenCacheKey = ['saml-token', accountId]
  const { data: samlRewards, isError: isRewardsError } =
    useQuery<RewardsResponse>(
      samlTokenCacheKey,
      () => getRewardsResponse(accountId),
      {
        enabled:
          Boolean(ssoEnabled) &&
          Boolean(accountId) &&
          Boolean(rewardsPlatformDestination)
      }
    )

  const handleAccountServicesClick = () => {
    dispatch(updateSelectedAccount(accountId))
    navigate(routes.ACCOUNT_SERVICES)
  }

  const debouncedStatementsClick = debounce(() => {
    handleAccountLink(account, clientId, windowWidth, 'statements')
  }, 250)

  const handleStatementsClick = () => {
    if (featureFlags.isStatementsMfeEnabled) {
      analyticsConfig.defaults.Product = productType
      analyticsConfig.defaults.ClientName = clientId
      dispatch(updateSelectedAccount(accountId))
      dispatch(updateModalStatus('Statement', statementsButtonId))
    } else {
      debouncedStatementsClick()
    }
  }

  const handleRewardsClick = () => {
    const samlToken = samlRewards?.samlToken

    if (accessToRewardsMfe) {
      dispatch(updateSelectedAccount(accountId))
      dispatch(updateModalStatus('Rewards', rewardsButtonId))
      return
    }

    if (isRewardsError) {
      navigate(routes.REWARDS_ERROR)
      return
    }

    if (rewardsPlatformDestination && ssoEnabled) {
      handleRewardsPlatform(samlToken, rewardsPlatformDestination)
      queryClient.invalidateQueries(samlTokenCacheKey)
    } else if (rewardsPlatformDestination && !ssoEnabled) {
      setIsCrossBorderVisible(true)
    } else {
      handleAccountLink(account, clientId, windowWidth, 'rewards')
    }
  }

  const handleActivityClick = () => {
    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    dispatch(updateModalStatus('Activity', activityButtonId))
    dispatch(updateSelectedAccount(accountId))
  }

  const handleDetailsClick = () => {
    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    dispatch(updateModalStatus('Details', detailsButtonId))
    dispatch(updateSelectedAccount(accountId))
  }

  const handleCollectionClick = async () => {
    const languageId = localStorage.getItem('language')
    await handleRevokeAccessToken()
    clearSessionStorage()
    redirectToUrl(
      `${config.DCH_HOST_NAME}/?account=${accountId}&lang=${languageId}`
    )
  }

  const handleCBRClick = () => {
    analyticsConfig.defaults.Product = product?.type
    analyticsConfig.defaults.ClientName = product?.clientId
    dispatch(updateSelectedAccount(accountId))
    navigate(routes.CREDIT_BALANCE_REFUND)
  }

  const handleMAPClick = () => {
    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    analyticsConfig.defaults.PageKind = 'payments'

    if (account.registration.registered) {
      dispatch(updateSelectedAccount(accountId))
      dispatch(updateModalStatus('MAP', paymentButtonId))
    } else {
      handoverToPayAsGuest(account.product, clientId)
    }
  }

  const handleRegisterAccountClick = () => {
    handoverToRegisterAccount(lineOfBusiness, clientId)
  }

  const handleAccountClick = () => {
    handleAccountLink(account, accountSummary?.product?.clientId, windowWidth)
  }

  const handlePromotionsClick = () => {
    dispatch(updateSelectedAccount(accountId))
    navigate(routes.PROMOTIONS)
  }

  return {
    handleActivityClick,
    handleCBRClick,
    handleCollectionClick,
    handleDetailsClick,
    handleMAPClick,
    handleRewardsClick,
    handleAccountServicesClick,
    handleStatementsClick,
    handleRegisterAccountClick,
    handleAccountClick,
    handlePromotionsClick
  }
}

export default useGetCTAHandlers
