import { useEffect, useState } from 'react'
import { differenceInCalendarDays } from 'date-fns'
import { ACCOUNT_OPEN } from 'const/account/conditions'
import { PSCC, RC } from 'const/account/products'
import featureFlags from 'const/featureFlags'
import {
  AllAccountSummaryGetResponse,
  ProfileGetResponse,
  useAllAccountSummary,
  useAllProfile,
  useCreditAccounts
} from 'const/syfApiQueries'
import { formatStringAsDate } from 'helpers/dateFormatters'

const CIPHER_ANNUAL_INCOME_LAST_UPDATED = 'cipher.annualIncomeLastUpdated'

export interface NotificationList {
  accountId: string
  accountName: string
}
interface QueryResult<T> {
  data: T[]
  isError: boolean
  isFetched: boolean
}

const defaultResult = {
  data: [],
  isError: false,
  isFetched: true
}

const useNotificationCenter = () => {
  const [notificationList, setNotificationList] = useState<
    Array<NotificationList>
  >([])
  const { data: { accounts = [] } = {}, isError: isCreditAccountsAPIError } =
    useCreditAccounts()

  const {
    data: allAccountSummaryData,
    isError: isAllAccountSummaryError,
    isFetched: isAllAccountSummaryFetched
  } = useAllAccountSummary(accounts).reduce<
    QueryResult<AllAccountSummaryGetResponse>
  >(
    (accountSummary, { data, isError, isFetched }) => ({
      data: [...accountSummary.data, data],
      isError: accountSummary.isError || isError,
      isFetched: accountSummary.isFetched && isFetched
    }),
    defaultResult
  )

  const {
    data: allProfileResponseData,
    isFetched: isAllProfileFetched,
    isError: isAllProfileError
  } = useAllProfile(accounts).reduce<QueryResult<ProfileGetResponse>>(
    (profileData, { data, isError, isFetched }) => ({
      data: [...profileData.data, data],
      isError: profileData.isError || isError,
      isFetched: profileData.isFetched && isFetched
    }),
    defaultResult
  )

  const shouldShowNotificationCenter =
    featureFlags.isNotificationCenterEnabled &&
    notificationList?.length > 0 &&
    !isCreditAccountsAPIError &&
    !isAllAccountSummaryError &&
    !isAllProfileError

  useEffect(() => {
    if (
      isAllProfileFetched &&
      isAllAccountSummaryFetched &&
      !isAllAccountSummaryError &&
      !isAllProfileError
    ) {
      const notificationAccounts: NotificationList[] = []
      allProfileResponseData.forEach((profileData) => {
        const summaryData = allAccountSummaryData.find(
          (item) => item?.accountId === profileData.accountId
        )
        const isRevolving = [RC, PSCC].includes(
          summaryData?.product?.lineOfBusiness
        )
        const isStatusOpen = summaryData?.conditions?.includes(ACCOUNT_OPEN)

        if (isRevolving && isStatusOpen) {
          const annualIncomeLastUpdatedDate =
            profileData.profile[CIPHER_ANNUAL_INCOME_LAST_UPDATED] &&
            formatStringAsDate(
              profileData?.profile[CIPHER_ANNUAL_INCOME_LAST_UPDATED],
              '/'
            )
          const profileLastUpdateDays = annualIncomeLastUpdatedDate
            ? differenceInCalendarDays(new Date(), annualIncomeLastUpdatedDate)
            : 241

          if (profileLastUpdateDays > 240) {
            notificationAccounts.push({
              accountId: profileData?.accountId,
              accountName: profileData?.accountName
            })
          }
        }
      })
      setNotificationList(notificationAccounts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllProfileFetched, isAllAccountSummaryFetched])

  return {
    notificationList,
    shouldShowNotificationCenter
  }
}

export default useNotificationCenter
