import { ReactElement, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import { changePaymentDueDateModalContainerId } from 'const/modalContainerId'
import i18n from 'strings/i18n'
import { ModalHeader } from 'syf-component-library/ui/patterns/SlideInModalPortal'

import { ChangePaymentDueDate } from 'account-services-mfe'
import getMFEConfig from 'helpers/getMFEConfig'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import SlideInModalPortal from './subcomponents'

const ChangePaymentDueDateModal = (): ReactElement => {
  const dispatch = useDispatch()
  const headerRef = useRef(null)

  const { selectedAccount, modalId, modalTriggerId } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId'),
      modalId: state.modals.modalId,
      modalTriggerId: state.modals.modalTriggerId
    }),
    shallowEqual
  )

  const mfeConfig = getMFEConfig('mfe')

  const handleCloseModal = () => {
    dispatch(updateModalStatus(''))
    triggerAnalyticsEvent({
      pageFunction: 'account services',
      pageKind: 'account services'
    })
  }

  return (
    <SlideInModalPortal
      containerId={changePaymentDueDateModalContainerId(selectedAccount)}
      escapeModalHandler={handleCloseModal}
      id={`cpdd-slide-in-modal-${selectedAccount}`}
      isOpen={modalId === 'ChangePaymentDueDate'}
      modalTriggerId={modalTriggerId}
      header={
        <ModalHeader
          closeModal={handleCloseModal}
          content={i18n({ modal: 'changeDueDateTitle' }) as string}
          data-test="cpdd-modal-header"
          dataType="x button"
          headerRef={headerRef}
        />
      }
    >
      <ChangePaymentDueDate
        config={mfeConfig}
        handleClose={handleCloseModal}
        selectedAccountId={selectedAccount}
      />
    </SlideInModalPortal>
  )
}

export default ChangePaymentDueDateModal
