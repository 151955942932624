import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import getMFEConfig from 'helpers/getMFEConfig'
import { statementsModalContainerId } from 'const/modalContainerId'
import useProfileNavigate from 'hooks/useProfileNavigate'
import StatementsMFE from 'statements-mfe'

const StatementsModal = (): ReactElement => {
  const dispatch = useDispatch()
  const { selectedAccount, modalId } = useSelector((state: RootState) => ({
    selectedAccount: state.modals.selectedAccount,
    modalId: state.modals.modalId,
    modalTriggerId: state.modals.modalTriggerId
  }))

  const statementDetailConfig = getMFEConfig('mfe')

  const { handleProfileClick } = useProfileNavigate()

  const handleCloseModal = () => {
    dispatch(updateModalStatus('', '', true))
  }

  return (
    <StatementsMFE
      config={statementDetailConfig}
      containerId={statementsModalContainerId(selectedAccount)}
      selectedAccountId={selectedAccount}
      isModalOpen={modalId === 'Statement'}
      handleClose={handleCloseModal}
      handleProfileClick={handleProfileClick}
    />
  )
}

export default StatementsModal
