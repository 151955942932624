export const activityModalContainerId = (guid: string): string =>
  `activity-modal-containerId${guid}`

export const makeAPaymentModalContainerId = (guid: string): string =>
  `make-a-payment-modal-containerId${guid}`

export const accountDetailsModalContainerId = (guid: string): string =>
  `account-details-modal-containerId${guid}`

export const statementsModalContainerId = (guid: string): string =>
  `statements-modal-containerId${guid}`

export const rewardsModalContainerId = (guid: string): string =>
  `rewards-modal-containerId${guid}`

export const creditLimitIncreaseContainerId = (guid: string): string =>
  `credit-limit-increase-modal-containerId${guid}`

export const manageCardHoldersModalContainerId = (guid: string): string =>
  `manage-card-holders-modal-containerId${guid}`

export const changePaymentDueDateModalContainerId = (guid: string): string =>
  `change-payment-due-date-modal-containerId${guid}`
