import decodeJWTToken from 'authorization-module/helpers/decodeJWTToken'
import endpoints from 'const/apiEndpoints'
import api from 'services'
import featureFlags from 'const/featureFlags'

let eventDispatchCounter = 0

export const addOneTrustEvent = () => {
  const oneTrustEvent = new CustomEvent('oneTrustEvent')
  window.dispatchEvent(oneTrustEvent)
}

export const initializeOneTrust = async () => {
  if (eventDispatchCounter < 1) {
    eventDispatchCounter = 1
    if (!featureFlags.isShowCookieSetting) {
      return
    }

    const idToken = sessionStorage.getItem('idToken')
    const { subject } = decodeJWTToken(idToken)
    let token: string

    if (subject) {
      try {
        const { data } = await api.get(endpoints.JWT_TOKEN)
        token = data.token
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      } finally {
        window.OneTrust = {
          dataSubjectParams: {
            id: subject,
            isAnonymous: false,
            token
          }
        }
        addOneTrustEvent()
      }
    } else {
      window.OneTrust = {
        dataSubjectParams: {
          id: undefined,
          isAnonymous: false,
          token: undefined
        }
      }
      addOneTrustEvent()
    }
  }
}
