import { ReactElement, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import Activity from 'account-activity-mfe'
import { SETPAY } from 'const/account/products'
import { defaultCardArt } from 'const/assets'
import { activityModalContainerId } from 'const/modalContainerId'
import { useAccountSummary, useCreditAccounts } from 'const/syfApiQueries'
import findAccount from 'helpers/findAccount'
import getMFEConfig from 'helpers/getMFEConfig'
import useHandleNavigate from 'hooks/useHandleNavigate'
import i18n from 'strings/i18n'
import DrawerHeader from 'syf-component-library/ui/atoms/DrawerHeader'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import ModalHeader from 'syf-component-library/ui/patterns/SlideInModal/Header'
import { SlideInModalPortal } from 'syf-component-library/ui/patterns/SlideInModalPortal'
import { Inline, Inset, Stack } from 'syf-component-library/ui/spacing'

import {
  DrawerHeaderWrapper,
  HorizontalBorder,
  ModalFooter,
  TabsContainer,
  TabsWrapper
} from './subcomponents'

const ActivityModal = (): ReactElement => {
  const { selectedAccount, modalId, modalTriggerId } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId'),
      modalId: state.modals.modalId,
      modalTriggerId: state.modals.modalTriggerId
    }),
    shallowEqual
  )
  const windowWidth = useWindowSize()
  const { data: { accounts = [] } = {} } = useCreditAccounts()
  const { data: accountSummary } = useAccountSummary(selectedAccount)

  const { handleNavigate } = useHandleNavigate()
  const headerRef = useRef(null)
  const dispatch = useDispatch()

  const account = findAccount(accounts, selectedAccount)
  const mmaConfig = getMFEConfig('mma')

  const accountLast4 = account?.info.accountLast4
  const isSetpayAccount = account?.product?.type === SETPAY

  const drawerProps = {
    title: accountSummary?.product?.displayName || 'Your Synchrony Account',
    subtitle: `${i18n({
      labels: isSetpayAccount ? 'loanEndingIn' : 'accountEndingIn'
    })} ${accountLast4}`,
    img: accountSummary?.card?.cardArtURL || defaultCardArt,
    fallbackImage: defaultCardArt,
    alt: ''
  }

  const handleCloseModal = () => {
    dispatch(updateModalStatus('', '', true))
  }

  const handleEscapeModal = () => {
    const questionnaireModal = document.getElementById('disputes-questionnaire')
    if (questionnaireModal) {
      // Prevent closing Activity Modal when Questionnaire is open
      return
    }
    dispatch(updateModalStatus('', '', true))
  }

  const handleCloseAndNavigate = (feature: string) => {
    // Close Activity Modal and navigate to Dispute Center
    handleCloseModal()
    handleNavigate(feature)
  }

  return (
    <SlideInModalPortal
      id="slide-in-modal"
      escapeModalHandler={handleEscapeModal}
      containerId={activityModalContainerId(selectedAccount)}
      isOpen={modalId === 'Activity'}
      modalTriggerId={modalTriggerId}
      header={
        <ModalHeader
          hideClose={!windowWidth.isLessThanMobile}
          closeModal={handleCloseModal}
          headerRef={headerRef}
          content={i18n({ labels: 'recentActivity' }) as string}
          data-test="modal-header"
        />
      }
      footer={<ModalFooter closeModal={handleCloseModal} />}
    >
      <DrawerHeaderWrapper
        left="base"
        top="base"
        bottom="medium"
        data-test="modal-drawer"
      >
        <DrawerHeader {...drawerProps} />
      </DrawerHeaderWrapper>
      <Stack size="large" hideOnBreakpointDown="small" />
      <Inset vertical="base" hideOnBreakpointUp="small">
        <HorizontalBorder />
      </Inset>
      <TabsWrapper data-test="modal-tabs">
        <Inline size="xsmall" />
        <TabsContainer>
          <Activity
            config={mmaConfig}
            display="condensed"
            guidAccountId={selectedAccount}
            navigationHandler={handleCloseAndNavigate}
            totalTransactions="7"
          />
        </TabsContainer>
        <Inline size="xsmall" />
      </TabsWrapper>
    </SlideInModalPortal>
  )
}

export default ActivityModal
