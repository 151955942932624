import { ReactElement, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import { manageCardHoldersModalContainerId } from 'const/modalContainerId'
import i18n from 'strings/i18n'
import { ModalHeader } from 'syf-component-library/ui/patterns/SlideInModalPortal'

import {
  AuthenticationRedirectProps,
  handleMFEAuthenticationRedirect
} from 'helpers/authenticationRedirect'
import routes from 'const/routes'
import { ManageCardHolders } from 'account-services-mfe'
import getMFEConfig from 'helpers/getMFEConfig'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import SlideInModalPortal from './subcomponents'

const ManageCardholderModal = (): ReactElement => {
  const dispatch = useDispatch()
  const headerRef = useRef(null)

  const { selectedAccount, modalId, modalTriggerId } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId'),
      modalId: state.modals.modalId,
      modalTriggerId: state.modals.modalTriggerId
    }),
    shallowEqual
  )

  const mfeConfig = getMFEConfig('mfe')

  const containerId = manageCardHoldersModalContainerId(selectedAccount)
  const parentElement = document.getElementById(containerId)

  const handleCloseModal = () => {
    dispatch(updateModalStatus(''))
    triggerAnalyticsEvent({
      pageFunction: 'account services',
      pageKind: 'account services'
    })
  }

  const handleAuthFlow = () => {
    const props: AuthenticationRedirectProps = {
      navigateTo: routes.ACCOUNT_SERVICES,
      modal: 'ManageCardholders',
      scope: 'read write write:sensitive'
    }

    handleMFEAuthenticationRedirect(props)
  }

  return (
    modalId === 'ManageCardholders' &&
    parentElement && (
      <SlideInModalPortal
        containerId={containerId}
        escapeModalHandler={handleCloseModal}
        id={`mch-slide-in-modal-${selectedAccount}`}
        isOpen={modalId === 'ManageCardholders'}
        modalTriggerId={modalTriggerId}
        header={
          <ModalHeader
            closeModal={handleCloseModal}
            content={i18n({ button: 'manageCardholders' }) as string}
            data-test="mch-modal-header"
            dataType="x button"
            headerRef={headerRef}
          />
        }
      >
        <ManageCardHolders
          authenticationRedirect={handleAuthFlow}
          config={mfeConfig}
          handleClose={handleCloseModal}
          selectedAccountId={selectedAccount}
        />
      </SlideInModalPortal>
    )
  )
}

export default ManageCardholderModal
