import { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

import Activity from 'account-activity-mfe'
import getMFEConfig from 'helpers/getMFEConfig'
import useHandleNavigate from 'hooks/useHandleNavigate'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'

import ActivityTableWrapper from './subcomponents'

const ActivityPage = (): ReactElement => {
  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )

  const { handleNavigate } = useHandleNavigate()

  useNavigateOnRedirect()

  const mmaConfig = getMFEConfig('mma')

  return (
    <ActivityTableWrapper>
      <Activity
        config={mmaConfig}
        guidAccountId={selectedAccount}
        navigationHandler={handleNavigate}
      />
    </ActivityTableWrapper>
  )
}

export default ActivityPage
