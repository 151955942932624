import { ReactElement, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import i18n from 'strings/i18n'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { SlideInModalPortal } from 'syf-component-library/ui/patterns/SlideInModalPortal'
import ModalHeader from 'syf-component-library/ui/patterns/SlideInModalPortal/Header'
import getMFEConfig from 'helpers/getMFEConfig'
import { format, parse } from 'date-fns'
import { useAddAccountMutation } from 'services/AddAccountMutation'
import { SlideInModalLoader } from 'syf-component-library/ui/atoms'
import { UNPROCESSIBLE_CODE } from 'const/errorCodes'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import strings from 'strings/lang/en'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import useForm, { initialValue } from './helpers/useForm'
import { getErrorScreenType } from './helpers/getErrorScreenType'
import { renderScreen } from './helpers/renderScreen'
import { BusinessErrors, ScreenType } from './typings'
import {
  ADD_ACCOUNT,
  SNAPSHOT,
  getAnalyticsData
} from './helpers/getAnalyticsData'

const AddAccountModal = (): ReactElement => {
  const [screenType, setScreenType] = useState<ScreenType>('Default')
  const manageFormState = useForm()

  const headerRef = useRef(null)
  const windowWidth = useWindowSize()

  const mfeConfig = getMFEConfig('mfe')

  const { mutateAsync, isLoading } = useAddAccountMutation({
    config: mfeConfig
  })

  const dispatch = useDispatch()
  const { modalId, modalTriggerId } = useSelector(
    (state: RootState) => ({
      modalId: state.modals.modalId,
      modalTriggerId: state.modals.modalTriggerId
    }),
    shallowEqual
  )

  useEffect(() => {
    if (modalId === 'AddAccount') {
      const { pageView } = getAnalyticsData(screenType)
      const errorMessage =
        screenType === 'IncorrectInfo'
          ? strings.errors.incorrectInfoDescripton
          : ''
      analyticsConfig.defaults.PageKind = ADD_ACCOUNT
      analyticsConfig.defaults.PageFunction = SNAPSHOT
      analyticsConfig.defaults.PageSubFunction = ''
      triggerAnalyticsEvent({
        ErrorMessage: errorMessage,
        ...pageView
      })
    }
  }, [screenType, modalId])

  const handleCloseModal = () => {
    const { setFormData, setFormError } = manageFormState
    dispatch(updateModalStatus('', '', true))
    setFormData(initialValue)
    setFormError(initialValue)
    setScreenType('Default')
  }

  const handleAddAccount = async () => {
    const { validateInput, isFormValid, formData, setFormData } =
      manageFormState
    validateInput()

    if (isFormValid.current) {
      const { accountNumber, ssn, dob } = formData
      const dateOfBirth = format(
        parse(dob, 'MM/dd/yyyy', new Date()),
        'yyyy-MM-dd'
      )
      try {
        const { success } = await mutateAsync({
          accountNumber,
          ssn,
          dateOfBirth
        })
        if (success) {
          setScreenType('Success')
        }
      } catch (error) {
        const err = await error.response?.json()
        const status = error.response?.status as number
        let businessErrors: Array<BusinessErrors> = []
        if (status === UNPROCESSIBLE_CODE) {
          businessErrors = err.businessErrors
        }
        setScreenType(getErrorScreenType(status, businessErrors[0]?.code))
      } finally {
        setFormData(initialValue)
      }
    } else {
      triggerAnalyticsEvent({
        ErrorMessage: 'field error',
        MessageType: 'entered invalid information',
        pageName: 'error'
      })
    }
  }

  return (
    <SlideInModalPortal
      id="slide-in-modal"
      escapeModalHandler={handleCloseModal}
      containerId="add-account-container-id"
      isOpen={modalId === 'AddAccount'}
      modalTriggerId={modalTriggerId}
      header={
        <ModalHeader
          hideClose={!windowWidth.isLessThanMobile}
          closeModal={handleCloseModal}
          headerRef={headerRef}
          content={i18n({ labels: 'addAccount' }) as string}
          data-test="add-account-modal-header"
        />
      }
    >
      {isLoading ? (
        <SlideInModalLoader />
      ) : (
        renderScreen(
          screenType,
          manageFormState,
          !windowWidth.isLessThanMobile,
          handleAddAccount,
          handleCloseModal
        )
      )}
    </SlideInModalPortal>
  )
}

export default AddAccountModal
