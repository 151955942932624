export const defaultDataSubjectParams = {
  id: undefined,
  isAnonymous: false,
  token: undefined
}

const getDataSubjectParams = () => {
  try {
    const correlationId = sessionStorage.getItem('correlationId')
    if (!correlationId) return defaultDataSubjectParams
    return JSON.parse(sessionStorage.getItem(correlationId))
  } catch (error) {
    return defaultDataSubjectParams
  }
}

export default getDataSubjectParams
