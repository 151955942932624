import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import CLButton from 'syf-component-library/ui/atoms/Button/Button'

const buttonDefaultStyles = `
  margin: 0;
  padding: 0;
  width: 100%;
`

export const Button = styled(CLButton)`
  ${buttonDefaultStyles}
`

export const CreditBalanceRefundButtonStyle = styled(Button)`
  @media ${query.greaterThanSmall} {
    padding: 0 6px;
  }
`
