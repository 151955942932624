/* eslint-disable react/no-deprecated */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from 'App'
import config from 'const/config'
import routes from 'const/routes'
import { isMockData } from 'syf-js-utilities'
import queryClient from 'const/queryClient'

const BASENAME = `${config.BASENAME}${routes.HOME}`

if (process.env.NODE_ENV !== 'production') {
  import('@axe-core/react').then(({ default: axe }) => {
    axe(React, ReactDOM, 1000)
  })
}

if (
  !window.location.pathname.includes(BASENAME) &&
  process.env.NODE_ENV !== 'production'
) {
  window.history.replaceState('', '', BASENAME)
}

;(async () => {
  const isUsingMockData = isMockData(config.APP_ENV)

  if (process.env.NODE_ENV !== 'production' && isUsingMockData) {
    const { default: mswBrowser } = await import('mocks/mswBrowser')
    queryClient.removeQueries()
    sessionStorage.setItem('mockData', 'TRUE')
    await mswBrowser?.start({
      onUnhandledRequest: 'bypass',
      serviceWorker: {
        url: `${BASENAME}mockServiceWorker.js`
      }
    })
  }

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename={BASENAME}>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('vista-root')
  )
})()
