import { ReactElement } from 'react'

import getMFEConfig from 'helpers/getMFEConfig'

import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'

import { RewardsPage } from 'rewards-mfe'

const Rewards = (): ReactElement => {
  useNavigateOnRedirect()

  const mfeConfig = getMFEConfig('mfe')

  return <RewardsPage config={mfeConfig} />
}

export default Rewards
