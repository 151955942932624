import { ReactElement, useEffect, useRef } from 'react'

import AddAccountButton from 'App/AddAccountModal/AddAccountButton'
import Loader from 'authorization-module/ui/atoms/Loader'
import featureFlags from 'const/featureFlags'
import queryClient from 'const/queryClient'
import {
  useAllAccountSummary,
  useAutoRegisterUpdate,
  useCreditAccounts
} from 'const/syfApiQueries'
import getAutoRegisterPayload from 'helpers/getAutoRegisterPayload'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import WelcomeInterstitial from 'pages/AccountsDashboard/WelcomeInterstitial'
import i18n from 'strings/i18n'
import { creditAccountsCacheKey } from 'syf-api'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import PersonalGreeting from 'ui/patterns/PersonalGreeting'

import useNotificationCenter from 'hooks/useNotificationCenter'
import NotificationCenter from 'ui/atoms/NotificationCenter'
import DynamicYieldGrid from './DynamicYieldGrid'
import {
  AccountManagerContainer,
  AccountManagerGrid,
  AccountManagerText,
  AccountsContainer,
  DashboardAccountGrid,
  DashboardContainer,
  MainContainer,
  NotificationContainer,
  PageWrapper
} from './subcomponents'
import TechnicalError from './TechnicalError'
import AccountCard from './Card/AccountCard'

const AccountsDashboard = (): ReactElement => {
  // To restrict from running in loop wrt creditaccount in case of any API issues
  const autoregisterCounter = useRef(0)
  const { isLoading: isAutoRegisterLoading, mutate: mutateAutoRegister } =
    useAutoRegisterUpdate()

  const {
    data: { accounts = [], electronicConsent } = {},
    isLoading: isAccountsLoading,
    isFetching: isAccountsFetching,
    error: accountError,
    isError: isCreditAccountsAPIError
  } = useCreditAccounts({
    onSuccess: (data) => {
      if (data.electronicConsent) {
        const accountsToAutoRegister = getAutoRegisterPayload(data.accounts)

        if (accountsToAutoRegister.length && autoregisterCounter.current < 1) {
          mutateAutoRegister(accountsToAutoRegister, {
            onSuccess: () => {
              queryClient.invalidateQueries(creditAccountsCacheKey())
              autoregisterCounter.current += 1
            }
          })
        }
      }
    }
  })
  const { shouldShowNotificationCenter, notificationList } =
    useNotificationCenter()

  const numberOfAccounts = accounts?.length

  useEffect(() => {
    if (isCreditAccountsAPIError) {
      triggerAnalyticsEvent({
        pageName: 'error',
        MessageType: 'technical error',
        ErrorMessage: `${i18n({ errors: 'technicalDifficulties' })}`
      })
    }
  }, [isCreditAccountsAPIError])

  const isAllAccountSummaryLoading = useAllAccountSummary(accounts).some(
    (queryData) => queryData.isLoading
  )

  const isAllAccountSummaryError = useAllAccountSummary(accounts).some(
    (queryData) => queryData.isError
  )

  const { isDYEnabled } = featureFlags
  const isAccountEmpty = !numberOfAccounts
  const previouslyVisited = localStorage.getItem('previouslyVisited')
  const isAccountsOrAutoRegisterLoading =
    isAccountsLoading || isAutoRegisterLoading
  const showDYCampaigns = !(
    isAllAccountSummaryLoading || isAccountsOrAutoRegisterLoading
  )
  const isCreditOrSummaryError =
    isCreditAccountsAPIError || isAllAccountSummaryError
  const renderWelcomeInterstitial =
    !isAccountsLoading &&
    (!previouslyVisited || !electronicConsent) &&
    !isAutoRegisterLoading &&
    !accountError &&
    !isAccountEmpty

  const showAddAccount =
    !isCreditAccountsAPIError &&
    !isAccountsLoading &&
    featureFlags.isAddAccountEnabled

  const pageInfo = {
    PageFunction: 'dashboard',
    PageKind: 'multiaccount',
    previouslyVisited: `${Boolean(previouslyVisited)}`
  }

  useEffect(() => {
    analyticsConfig.defaults.PageKind = 'multiaccount'
    // triggered on initial call and add account
    if (numberOfAccounts) {
      triggerAnalyticsEvent({
        ...pageInfo,
        numberOfAccounts: `${numberOfAccounts}`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfAccounts])

  useEffect(() => {
    // triggered on initial call and invalidateQueries for 0 accounts
    if (
      !isCreditAccountsAPIError &&
      !isAccountsFetching &&
      numberOfAccounts === 0
    ) {
      triggerAnalyticsEvent({
        ...pageInfo,
        pageName: 'error',
        MessageType: 'technical error',
        ErrorMessage: `${i18n({ errors: 'unableToFindAccount' })}`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfAccounts, isAccountsFetching, isCreditAccountsAPIError])

  return (
    <PageWrapper data-test="account-dashboard">
      {renderWelcomeInterstitial && <WelcomeInterstitial />}
      <DashboardContainer vertical="base" hideOnBreakpointDown="small">
        <PersonalGreeting />
        {isAccountsOrAutoRegisterLoading ? (
          <Loader data-test="syf-loader" />
        ) : (
          <MainContainer
            shouldShowNotificationCenter={shouldShowNotificationCenter}
          >
            <AccountsContainer>
              {showAddAccount && (
                <AccountManagerContainer>
                  <AccountManagerGrid id="add-account-container-id">
                    <AccountManagerText as="h2" weight="bold">
                      {i18n({ labels: 'yourAccounts' })}
                    </AccountManagerText>
                    <AddAccountButton />
                  </AccountManagerGrid>
                </AccountManagerContainer>
              )}
              <DashboardAccountGrid
                className="dashboard-accounts-grid"
                shouldShowNotificationCenter={shouldShowNotificationCenter}
              >
                {accountError || isAccountEmpty ? (
                  <TechnicalError
                    errorType={
                      accountError ? 'technicalError' : 'noAccountsFound'
                    }
                  />
                ) : (
                  accounts.map((account, index) => {
                    const { info } = account
                    const accountId = info['cipher.accountId']
                    return (
                      <AccountCard
                        key={accountId}
                        cardIndex={index}
                        accountId={accountId}
                        data-test="account-card"
                      />
                    )
                  })
                )}
              </DashboardAccountGrid>
            </AccountsContainer>
            {shouldShowNotificationCenter && (
              <NotificationContainer data-test="notification-center">
                <NotificationCenter accounts={notificationList} />
              </NotificationContainer>
            )}
          </MainContainer>
        )}
      </DashboardContainer>
      {showDYCampaigns &&
        isDYEnabled &&
        !isCreditOrSummaryError &&
        !isAccountEmpty && <DynamicYieldGrid />}
    </PageWrapper>
  )
}

export default AccountsDashboard
