import { ReactElement } from 'react'

import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import i18n from 'strings/i18n'
import { Button } from 'syf-component-library/ui/atoms'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import { Body } from 'syf-component-library/ui/typography'
import theme from 'theme'

import { CancelButton, FooterWrapper, Icon, Modal } from './subcomponents'

interface CrossBorderModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  handleOpenTab: () => void
  dataType?: string
}

const CrossBorderModal = ({
  isOpen,
  setIsOpen,
  handleOpenTab,
  dataType
}: CrossBorderModalProps): ReactElement => {
  const handleEscapeAndCancelTab = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      id="cross-border"
      title="Cross Border Modal"
      isOpen={isOpen}
      data-test="cross-border-modal"
      containerId="cross-border-modal"
      escapeModalHandler={handleEscapeAndCancelTab}
      footerButtons={[
        <FooterWrapper key="cross-border-footer">
          <CancelButton
            buttonType="secondary"
            onClick={handleEscapeAndCancelTab}
            data-type={dataType}
            data-reason="cancel"
            data-test="cancel-cross-border"
            data-object="button"
            data-analytics="clicktrackingevent"
          >
            {i18n({ button: 'cancel' })}
          </CancelButton>
          <Button
            buttonType="primary"
            onClick={handleOpenTab}
            data-type={dataType}
            data-reason="open tab"
            data-test="open-tab-cross-border"
            data-object="button"
            data-analytics="clicktrackingevent"
          >
            {i18n({ button: 'crossBorderOpenTab' })}
          </Button>
        </FooterWrapper>
      ]}
    >
      <Inset top="xxlarge" bottom="medium" vertical="xxlarge">
        <Icon color={theme.cyanBlue} size="2x" icon={faTriangleExclamation} />
        <Stack size="large" />
        <Body>{i18n({ labels: 'crossBorderText' })}</Body>
      </Inset>
    </Modal>
  )
}

export default CrossBorderModal
