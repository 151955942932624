import styled from 'styled-components'
import mediaQueries from 'syf-component-library/const/mediaQueries'
import { fontSizes } from 'syf-component-library/defaultTheme'
import { H1 } from 'syf-component-library/ui/typography'

export const CreditViewPageWrapper = styled.div`
  max-width: 1200px;
  width: 95%;
  margin: 100px auto 32px auto;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.white};
  padding-bottom: 80px;

  @media ${mediaQueries.lessThanMedium} {
    width: 100%;
    margin: 0;
    padding-bottom: 24px;
    border-radius: unset;
  }
`

export const CreditViewImage = styled.img`
  width: 100%;
  transform: translateY(-67px);
  margin-bottom: -67px;
  height: 315px;
  @media ${mediaQueries.lessThanMedium} {
    height: 120px;
    transform: unset;
    margin-bottom: unset;
  }
`

export const CreditViewBody = styled.div`
  max-width: 890px;
  margin: 0 auto;
`

export const CreditViewTitle = styled(H1)`
  text-align: center;
  @media ${mediaQueries.lessThanMedium} {
    font-size: ${fontSizes.xLarge}px;
  }
`
export const StackLg = styled.div`
  height: 80px;

  @media ${mediaQueries.lessThanMedium} {
    height: 32px;
  }
`
