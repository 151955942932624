import styled, { keyframes } from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'

interface CardNumberProps {
  $cardIndex: number
}

const ClickableHeaderBorder = `
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  cursor: pointer;
`

export const CollapseHeaderWrapper = styled.button<{
  isExpanded: boolean
}>`
  padding: 0;
  color: inherit;
  background: none;
  border: none;
  text-align: left;
  box-shadow: none;
  width: 100%;
  ${({ isExpanded }) => isExpanded && ClickableHeaderBorder}
`

const slideCards = ($cardIndex: number) => keyframes`
  0% {
     left: calc(100% + ${$cardIndex} * 100%);
  }
  100% {
     left: 0;
  }
`
export const CardColumn = styled.div<CardNumberProps>`
  @media ${query.lessThanSmall} {
    position: relative;
    animation: ${({ $cardIndex }) => slideCards($cardIndex)} 700ms;
    transition: width 0.3s;
    width: calc(100% - ${spacing.small}px);
    padding-left: ${spacing.small}px;
  }
  @media ${query.greaterThanSmall} {
    flex-basis: unset;
  }
`
