import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAR_EXCLAMATION_TRIANGLE } from 'const/icons'
import routes from 'const/routes'
import i18n from 'strings/i18n'
import Button from 'syf-component-library/ui/atoms/Button'
import theme from 'theme'

import { Container, Content, Title } from './subcomponents'

const PageNotFound = (): ReactElement => {
  const navigate = useNavigate()

  return (
    <Container>
      <FontAwesomeIcon
        icon={FAR_EXCLAMATION_TRIANGLE}
        size="2x"
        fill={theme.darkGrey}
      />
      <Title weight="bold" data-test="page-not-found-header">
        {i18n({ errors: 'sorryPage' })}
      </Title>
      <Content>{i18n({ errors: 'pageNotFound' })}</Content>
      <Button
        data-test="go-home-button"
        buttonType="primary"
        onClick={() => navigate(routes.HOME)}
      >
        {i18n({ button: 'goBackHome' })}
      </Button>
    </Container>
  )
}

export default PageNotFound
