import styled from 'styled-components'
import { SlideInModalPortal as CLSlideInModalPortal } from 'syf-component-library/ui/patterns/SlideInModalPortal'

const SlideInModalPortal = styled(CLSlideInModalPortal)`
  div:has(#modal-content-wrapper) {
    height: 100%;
  }
`

export default SlideInModalPortal
